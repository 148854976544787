import React from 'react';
import PropTypes from 'prop-types';
import i18next from 'i18next';
import { Card, CardBody, CardHeader, Col, Row } from 'reactstrap';
import { Archive, Clock, Home, Phone, User } from 'react-feather';

import { currency } from 'globals/currency';
import { VIEWS } from 'config';
import { EDeliveryStatus } from 'enums';
import Shapes from 'shapes/main';
import DeliveryPaymentType from './DeliveryPaymentType';
import DriverArrivesIn from './DriverArrivesIn';
import DeliveryStatus from './DeliveryStatus';
import CollapsibleCard from '../../common/CollapsibleCard';
import type { IDelivery } from 'types';

const iconStyle = { height: 20 };

interface DeliveryTileProps {
  children?: React.ReactNode;
  delivery: IDelivery;
  isDriver: boolean;
  onPhoneClick?: (delivery: IDelivery) => void;
  showRestaurantName?: boolean;
  showStatus?: boolean;
  view?: 'grid' | 'list';
}

export const DeliveryTile: React.FC<DeliveryTileProps> = ({
  children,
  delivery,
  isDriver = false,
  onPhoneClick,
  showRestaurantName,
  showStatus = true,
  view = VIEWS.GRID.text,
}) => {
  const isList = view === VIEWS.LIST.text;
  const isGrid = !isList;

  const roleBasedView = isDriver ? 'driver-view' : 'restaurant-view';
  const renderPhone = (phone: string, handleClick?: () => void) => (
    <a href={`tel:${phone}`} onClick={handleClick}>
      {phone}
    </a>
  );

  const contactDetails = (
    <>
      <div className="align-vertical flex-wrap mb-1">
        <div className="align-vertical">
          <Home className="mr-1" style={iconStyle} />
          <a
            href={`http://maps.google.com/maps?q=${delivery.address}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            {delivery.address}
          </a>
        </div>
        <span className="ml-1">({delivery.distance}m)</span>
      </div>
      <div>
        <Phone style={iconStyle} /> {renderPhone(delivery.phone)}
      </div>
    </>
  );

  const paymentDetails = (
    <>
      {!!delivery.price && (
        <div>
          {i18next.t('delivery.price')}: {delivery.price} {currency}
        </div>
      )}
      <DeliveryPaymentType delivery={delivery} />
    </>
  );

  const priceForDriver = delivery.priceForDriver && (
    <div>
      {i18next.t('delivery.price_for_driver')}: {delivery.priceForDriver}{' '}
      {currency}
    </div>
  );

  const statusIfNeeded = showStatus && (
    <div>
      {i18next.t('delivery.status')}:{' '}
      {i18next.t(`delivery.${roleBasedView}.statuses.${delivery.status}`)}
    </div>
  );

  const driverData = !isDriver && !!delivery.user && (
    <>
      <div className="font-weight-bold mt-2">Kierowca: </div>
      <div>
        <User style={iconStyle} /> {delivery.user.name}
      </div>
      {delivery.user.phone && (
        <div>
          <Phone style={iconStyle} />{' '}
          <a href={`tel:${delivery.user.phone}`}>{delivery.user.phone}</a>
        </div>
      )}
    </>
  );

  const specialInfoPart = (
    <>
      <div className={delivery.delivery_at ? 'text-danger' : ''}>
        {delivery.status === EDeliveryStatus.NEW ? (
          <Clock className="mr-1" />
        ) : (
          ''
        )}
        {delivery.delivery_at
          ? i18next.t(`delivery.${roleBasedView}.delivery_at`, {
              delivery_at: delivery.delivery_at,
            })
          : delivery.status === EDeliveryStatus.NEW
          ? i18next.t(`delivery.${roleBasedView}.inform_before`, {
              inform_before: delivery.inform_before,
            })
          : ''}
      </div>
      <DriverArrivesIn delivery={delivery} isDriver={isDriver} />
      {delivery.notes && (
        <div className="text-danger my-2 font-weight-bold">
          {i18next.t('delivery.notes')}: {delivery.notes}
        </div>
      )}
    </>
  );

  const restaurantContactData = (
    <>
      {(isDriver || showRestaurantName) && (
        <div>
          <Archive className="mr-2" />
          {delivery.restaurant!.name}
        </div>
      )}
      {isDriver && (
        <div className="small mb-2">{delivery.restaurant!.location}</div>
      )}
      {isDriver && (
        <div>
          <Phone style={iconStyle} />{' '}
          {renderPhone(
            delivery.restaurant!.phone,
            onPhoneClick ? () => onPhoneClick(delivery) : undefined
          )}
        </div>
      )}
    </>
  );

  const title = (
    <DeliveryStatus className={isList ? 'mb-2' : ''} delivery={delivery} showId>
      {restaurantContactData}
      <hr className="delivery-divider my-2" />
      {isDriver && contactDetails}
      {isDriver && <hr className="delivery-divider my-2" />}
    </DeliveryStatus>
  );

  if (isDriver) {
    return (
      <CollapsibleCard
        className="w-100"
        tag="div"
        title={
          <>
            {title}
            {children}
          </>
        }
        bodyClassName="d-flex flex-column align-items-start"
        buttonClassName="p-2 w-100"
      >
        {
          <>
            {specialInfoPart}
            {contactDetails}
            {statusIfNeeded}
            {driverData}
            <hr className="delivery-divider" />
            {paymentDetails}
            {priceForDriver}
            <hr className="delivery-divider" />
          </>
        }
      </CollapsibleCard>
    );
  }

  return (
    <Card className={isList ? 'col-12' : 'col-12 col-md-6'}>
      {isGrid && <CardHeader>{title}</CardHeader>}
      <CardBody className={isList ? 'py-2' : ''}>
        {isGrid && (
          <>
            {specialInfoPart}
            {contactDetails}
            {statusIfNeeded}
            {driverData}
            <hr className="delivery-divider" />
            {paymentDetails}
            {priceForDriver}
            <hr className="delivery-divider" />
            {restaurantContactData}
            {children}
          </>
        )}
        {isList && (
          <>
            <Row>
              <Col>{title}</Col>
              <Col>
                {contactDetails}
                {statusIfNeeded}
                {driverData}
              </Col>
              <Col>
                {specialInfoPart}
                {paymentDetails}
                {priceForDriver}
                {restaurantContactData}
              </Col>
            </Row>
            <Row>{children}</Row>
          </>
        )}
      </CardBody>
    </Card>
  );
};

DeliveryTile.propTypes = {
  children: PropTypes.node,
  delivery: Shapes.deliveryShape.isRequired,
  isDriver: PropTypes.bool.isRequired,
  view: PropTypes.oneOf([VIEWS.GRID.text, VIEWS.LIST.text] as ['grid', 'list']),
  onPhoneClick: PropTypes.func,
  showStatus: PropTypes.bool,
};

export default DeliveryTile;
