import React, { Component } from 'react';
import { compose } from 'redux';
import { connect, ConnectedProps } from 'react-redux';
import { toast } from 'react-toastify';
import memoizeOne from 'memoize-one';

import withSetModal, { TSetModalFunction } from '../../containers/WithSetModal';
import { ModalTypes, cities } from 'config';
import { getSafeLsJSON } from 'utils/parsers';
import CollapsibleCard from 'app/components/common/CollapsibleCard';
import AdminRestaurantValidator from 'app/components/admin/AdminRestaurantValidator';
import {
  selectIsRootAdmin,
  selectIsLesznojeAdmin,
  selectIsLubjeAdmin,
} from 'store/app';
import VisibilityToggles, {
  IAdminVisibilityFilters,
} from 'app/components/admin/restaurant-list/VisibilityToggles';
import AdminRestaurantListItem from 'app/components/admin/restaurant-list/AdminRestaurantListItem';
import { OriginalRestaurant } from 'types/restaurants';
import {
  createRestaurant,
  updateRestaurant,
  updateRestaurantFinancialSettingsOrders,
  selectAdminRestaurants,
  selectAdminRestaurantsLoading,
  selectAdminRestaurantsUpdating,
} from 'store/admin';
import AdminRestaurantFilters, {
  getUniqueValues,
  productsInGroups,
} from 'app/components/admin/restaurant-list/AdminRestaurantFilters';
import AdminRestaurantsSummary from 'app/components/admin/restaurant-list/AdminRestaurantsSummary';
import { OtoSpinner } from 'app/components/common';
import AddRestaurantForm, {
  AddRestaurantFormOwnProps,
} from 'app/components/restaurants/AddRestaurantForm';
import { TOriginalRestaurantWithFinancialConfig, TRestaurantFinancialConfigOrders } from 'types';

const lsKey = 'admin-restaurants-list-visibility-toggles';

const getCuisineTypes = memoizeOne(
  (restaurants: OriginalRestaurant[], isShown): string[] => {
    if (!isShown) {
      return [];
    }
    return getUniqueValues(restaurants.map((r) => r.cuisine_type || ''));
  }
);

interface IAdminRestaurantsPageOwnProps {
  canEdit: boolean;
  isAdmin: boolean;
  restaurants: OriginalRestaurant[];
  restaurantsLoading: boolean;
  restaurantsUpdating: number[];
  setModal: TSetModalFunction;
}

interface IAdminRestaurantsPageProps extends IAdminRestaurantsPageOwnProps, PropsFromRedux {}

interface IAdminRestaurantsPageState {
  activeProducts: string[];
  amountToShow: number;
  visibility: IAdminVisibilityFilters;
  restaurantNameFilter: string;
  restaurantCityFilter: number;
}

class AdminRestaurantsPage extends Component<
  IAdminRestaurantsPageProps,
  IAdminRestaurantsPageState
> {
  constructor(props: IAdminRestaurantsPageProps) {
    super(props);
    const savedVisibility = getSafeLsJSON(lsKey, {});
    this.state = {
      activeProducts: [],
      amountToShow: 100,
      visibility: {
        showAllProducts: false,
        showCuisineLabels: false,
        showCuisineType: false,
        showContactDetails: false,
        showImages: false,
        ...savedVisibility,
      },
      restaurantNameFilter: '',
      restaurantCityFilter: 0,
    } as IAdminRestaurantsPageState;
  }

  setAmountToShow = (e) => {
    if (e && e.target) {
      this.setState({ amountToShow: parseFloat(e.target.value) });
    } else {
      this.props.setModal(
        {
          title: 'Podaj liczbę restauracji do wyświetlenia',
          confirm: (newAmount) =>
            this.setState({ amountToShow: parseFloat(newAmount) }),
          confirmColor: 'info',
        },
        ModalTypes.INPUT
      );
    }
  };

  addProduct = (restaurant: OriginalRestaurant, product: string) =>
    this.handleRestaurantUpdate(restaurant, {
      products: [...(restaurant.products || []), product],
    });

  removeProduct = (restaurant: OriginalRestaurant, product: string) =>
    this.handleRestaurantUpdate(restaurant, {
      products: (restaurant.products || []).filter((prod) => prod !== product),
    });

  updateVisibility = (newVisibility: IAdminVisibilityFilters) => {
    localStorage.setItem(lsKey, JSON.stringify(newVisibility));
    this.setState({ visibility: newVisibility });
  };

  handleRestaurantAdd: AddRestaurantFormOwnProps['onAddRestaurant'] = async (
    restaurantValues
  ) => {
    if (!this.props.canEdit) {
      toast.info('Nie masz uprawnień do dodawania restauracji');
      return {
        isSuccess: false,
      };
    }
    const response = await this.props.createRestaurant(restaurantValues);
    if ('errors' in response) {
      return {
        isSuccess: false,
        errors: response.errors,
      };
    }
    return {
      isSuccess: true,
    };
  };

  handleRestaurantUpdate = (
    restaurant: OriginalRestaurant,
    payload: Partial<OriginalRestaurant>
  ): void => {
    if (!this.props.canEdit) {
      toast.info('Nie masz uprawnień do edytowania restauracji');
      return;
    }
    this.props.updateRestaurant(restaurant, payload);
  };

  handleRestaurantFinancialSettingsUpdate = (
    restaurant: TOriginalRestaurantWithFinancialConfig,
    payload: TRestaurantFinancialConfigOrders
  ): void => {
    if (!this.props.canEdit) {
      toast.info('Nie masz uprawnień do edytowania restauracji');
      return;
    }

    this.props.updateRestaurantFinancialSettingsOrders(
      restaurant.finance_settings,
      payload,
    )
  }

  toggleProductSelected = (product: string) => {
    if (this.state.activeProducts.includes(product)) {
      const newActiveProducts = this.state.activeProducts.filter((item) => {
        return item !== product;
      });
      this.setState({ activeProducts: newActiveProducts });
    } else {
      this.setState({
        activeProducts: [...this.state.activeProducts, product],
      });
    }
  };

  getRestaurants = (restaurants: OriginalRestaurant[]) => {
    const { activeProducts, restaurantCityFilter, restaurantNameFilter } =
      this.state;
    return restaurants
      .filter((restaurant) => {
        if (activeProducts.length === 0) {
          return true;
        }
        return (restaurant.products || []).includes(activeProducts[0]);
      })
      .filter((r) =>
        restaurantCityFilter ? r.city_id === restaurantCityFilter : true
      )
      .filter((restaurant) =>
        restaurant.name
          .toLowerCase()
          .includes(restaurantNameFilter.toLowerCase())
      );
  };

  getSummary = memoizeOne(
    (restaurants: OriginalRestaurant[]): Record<string, number> => {
      return restaurants.reduce((summary, restaurant) => {
        (restaurant.products || []).forEach((product) => {
          if (summary[product]) {
            summary[product]++;
          } else {
            summary[product] = 1;
          }
        });
        return summary;
      }, {});
    }
  );

  handleNameFilterChange = (e) =>
    this.setState({ restaurantNameFilter: e.target.value });

  handleCityFilterChange = (e) =>
    this.setState({ restaurantCityFilter: parseInt(e.target.value, 10) });

  render = () => {
    const { restaurants, restaurantsLoading, restaurantsUpdating } = this.props;
    const { amountToShow, visibility } = this.state;

    if (restaurantsLoading) {
      return <OtoSpinner center />;
    }
    const allFilteredRestaurants = this.getRestaurants(restaurants);
    const filteredRestaurants = allFilteredRestaurants.slice(0, amountToShow);

    const summary = this.getSummary(restaurants);

    const allProducts = Object.keys(summary);
    const productsToShow = this.state.visibility.showAllProducts
      ? allProducts
      : productsInGroups;

    const availableCuisineTypes = getCuisineTypes(
      restaurants,
      visibility.showCuisineType
    );

    return (
      <>
        <AdminRestaurantsSummary
          amountToShow={amountToShow}
          filteredRestaurants={filteredRestaurants}
          restaurants={restaurants}
          summary={summary}
        />
        <CollapsibleCard title="Filtry">
          <AdminRestaurantFilters
            activeProducts={this.state.activeProducts}
            amountToShow={this.state.amountToShow}
            handleCityFilterChange={this.handleCityFilterChange}
            handleNameFilterChange={this.handleNameFilterChange}
            productsToShow={productsToShow}
            restaurantCityFilter={this.state.restaurantCityFilter}
            restaurantNameFilter={this.state.restaurantNameFilter}
            setAmountToShow={this.setAmountToShow}
            showCity={this.props.isAdmin}
            toggleProductSelected={this.toggleProductSelected}
          />
        </CollapsibleCard>
        <CollapsibleCard title="Opcje widoku - jakie pola pokazywać">
          <VisibilityToggles
            visibility={this.state.visibility}
            onUpdate={this.updateVisibility}
          />
        </CollapsibleCard>
        <AdminRestaurantValidator restaurants={filteredRestaurants} />
        <CollapsibleCard title="Dodaj restaurację">
          <AddRestaurantForm
            availableCities={cities}
            onAddRestaurant={this.handleRestaurantAdd}
          />
        </CollapsibleCard>
        <section>
          {filteredRestaurants.map((restaurant) => (
            <AdminRestaurantListItem
              key={restaurant.id}
              addProduct={this.addProduct}
              availableCuisineTypes={availableCuisineTypes}
              fieldsVisibility={visibility}
              isLoading={restaurantsUpdating.includes(restaurant.id)}
              productsToShow={productsToShow}
              removeProduct={this.removeProduct}
              restaurant={restaurant}
              setModal={this.props.setModal}
              onUpdateRestaurant={this.handleRestaurantUpdate}
              onRestaurantFinancialSettingsUpdate={this.handleRestaurantFinancialSettingsUpdate}
            />
          ))}
        </section>
      </>
    );
  };
}

const mapStateToProps = (state) => ({
  canEdit:
    selectIsRootAdmin(state) ||
    selectIsLesznojeAdmin(state) ||
    selectIsLubjeAdmin(state),
  isAdmin: selectIsRootAdmin(state),
  restaurants: selectAdminRestaurants(state),
  restaurantsLoading: selectAdminRestaurantsLoading(state),
  restaurantsUpdating: selectAdminRestaurantsUpdating(state),
});

const mapDispatchToProps = {
  createRestaurant,
  updateRestaurant,
  updateRestaurantFinancialSettingsOrders,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

const EnhancedAdminRestaurantsPage = compose(
  connector,
  withSetModal
)(AdminRestaurantsPage);

(EnhancedAdminRestaurantsPage as any).navName = 'admin.restaurants-list';
(EnhancedAdminRestaurantsPage as any).url = '/admin/restaurants-list';

export default EnhancedAdminRestaurantsPage;
